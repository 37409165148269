//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '@/components/Logo';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher.vue';

export default {
  name: 'Navigation',
  components: { LanguageSwitcher, Logo, ThemeSwitcher },
};
