//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FlagEN from '@/assets/svg/flag/en.svg';
import FlagNL from '@/assets/svg/flag/nl.svg';

export default {
  name: 'LanguageSwitcher',
  components: { FlagEN, FlagNL },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
  },
};
