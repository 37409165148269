//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import colors from '@/assets/scss/modules/_colors.module.scss';

export default {
  name: 'Logo',
  props: {
    background: {
      type: String,
      default: 'dark',
      validator: (val) => ['dark', 'light', 'mint'].includes(val),
    },
    color: {
      type: String,
      default: 'mint',
      validator: (val) => ['dark', 'light', 'mint'].includes(val),
    },
    size: {
      type: String,
      default: '64',
      validator: (val) => parseInt(val) >= 16,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    markFill() {
      return colors[this.color];
    },
    backgroundColor() {
      return colors[this.background];
    },
    minSpace() {
      return `${this.size / 4}px`;
    },
  },
};
