//
//
//
//
//
//

export default {
  name: 'NavigationItem',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
