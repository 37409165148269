//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconSystem from '@/assets/svg/icons/desktop-solid.svg';
import IconDark from '@/assets/svg/icons/moon-solid.svg';
import IconLight from '@/assets/svg/icons/sun-bright-solid.svg';
export default {
  name: 'ThemeSwitcher',
  components: { IconDark, IconLight, IconSystem },
  data() {
    return {
      availableThemes: ['system', 'light', 'dark'],
    };
  },
  computed: {
    nextMode() {
      let ind = this.availableThemes.indexOf(this.$colorMode.preference) + 1;
      if (ind > this.availableThemes.length - 1) ind = 0;
      if (ind < 0) ind = 0;
      return ind;
    },
  },
};
