//
//
//
//
//
//

export default {
  head() {
    return this.$nuxtI18nHead({ addSeoAttribute: true });
  },
};
