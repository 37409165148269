//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SkipToContent',
};
