//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommandBarResult from './CommandBarResult.vue';
export default {
  name: 'CommandBarResults',
  components: { CommandBarResult },
  props: {
    search: {
      type: String,
      required: true,
    },
    commands: {
      type: Array,
      required: false,
      default: () => [],
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedCommand: 0,
    };
  },
  computed: {
    results() {
      if (this.search === '') return this.commands;
      else return this.filterCommands(this.search);
    },
  },
  watch: {
    commands(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.selectedCommand = 0;
      }
    },
    selectedCommand(value) {
      const max = this.results.length - 1;
      if (value < 0) this.selectedCommand = max;
      if (value > max) this.selectedCommand = 0;
    },
  },
  beforeMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleKeyUp(e) {
      if (!this.active) return;
      if (e.key === 'ArrowDown') this.selectedCommand++;
      if (e.key === 'ArrowUp') this.selectedCommand--;
      if (e.key === 'Enter') {
        this.results[this.selectedCommand].action();
        if (this.results[this.selectedCommand].subcommands) return;
        this.$emit('close');
      }
    },
    filterCommands(search) {
      const reg = new RegExp(search, 'i');
      this.selectedCommand = 0;

      return this.commands.filter((item) => {
        return reg.test(item.label) || reg.test(item.keywords.join('|'));
      });
    },
  },
};
