// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mint": "#00ffa3",
	"dark": "#252837",
	"light": "#fff",
	"gradient2Start": "#6732ff",
	"gradient2End": "#ff6cb3"
};
module.exports = ___CSS_LOADER_EXPORT___;
