// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/error-blob.svg?inline");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-page{display:flex;align-items:center;justify-content:center;background-color:#6732ff;background-image:linear-gradient(45deg,#6732ff,#ff6cb3);min-height:100vh;min-width:100vw;position:relative;color:#fff;flex-direction:column;z-index:2}.error-page__title{margin-bottom:32px}.error-page__body{margin-bottom:64px}.error-page__button{align-self:center!important}.error-page:after{content:\"\";position:absolute;left:0;top:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;width:100%;height:100%;pointer-events:none;z-index:-1}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
