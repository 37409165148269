//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Error',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
