export const state = () => ({
  isShowingContactForm: false,
  theme: 'light',
});

export const mutations = {
  SHOW_CONTACT_FORM(state) {
    state.isShowingContactForm = true;
  },
  HIDE_CONTACT_FORM(state) {
    state.isShowingContactForm = false;
  },
  SET_THEME(state, payload) {
    localStorage.setItem('theme', payload);
    state.theme = localStorage.getItem('theme');
  },
};

export const actions = {
  showContactForm({ commit }) {
    commit('SHOW_CONTACT_FORM');
  },
  hideContactForm({ commit }) {
    commit('HIDE_CONTACT_FORM');
  },
  toggleTheme({ commit, state }) {
    const theme = state.theme === 'light' ? 'dark' : 'light';
    commit('SET_THEME', theme);
  },
};
