//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppFooter',
  data() {
    return {
      whatsappUrl: process.env.WHATSAPP_URL,
    };
  },
};
