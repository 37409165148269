//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'CommandBar',
  data() {
    return {
      search: '',
      pressedKeys: [],
      isShowingCommandBar: false,
      isShowingSubmenu: false,
      submenu: [],
      selectedCommand: 0,
      commands: [
        {
          id: 'contact',
          icon: '✉️',
          label: 'menu.contact',
          keywords: [
            'contact',
            'email',
            'e-mail',
            'whatsapp',
            'talk',
            "let's talk",
          ],
          action: () => this.$store.dispatch('showContactForm'),
        },
        {
          id: 'theme',
          icon: 'fa-solid fa-envelope',
          label: 'menu.theme',
          keywords: ['theme', 'dark', 'light', 'mode'],
          action: () => this.$store.dispatch('showContactForm'),
        },
        {
          id: 'change-language',
          label: 'menu.change_language',
          keywords: [
            'change',
            'language',
            'i18n',
            'nederlands',
            'english',
            'engels',
            'dutch',
          ],
          action: () => {
            if (this.$i18n.locale === 'en') {
              this.$router.push({
                path: '/',
              });
            } else {
              this.$router.push({
                path: '/en',
              });
            }
          },
          subcommands: [
            {
              label: 'Nederlands',
              keywords: ['dutch', 'nederlands'],
              commandKey: 'n',
              action: () => {},
            },
            {
              label: 'English',
              keywords: ['english', 'engels'],
              commandKey: 'e',
              action: () => {},
            },
          ],
        },
      ],
    };
  },
  computed: {
    activeMenu() {
      return this.isShowingSubmenu ? this.submenu : this.commands;
    },
    results() {
      if (this.search === '') return this.activeMenu;
      return this.filterByTextContains(this.search);
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions({
      showContact: 'showContactForm',
    }),
    handleAction(fn) {
      fn();
      this.closeCommandBar();
    },
    handleKeydown(e) {
      if (!this.pressedKeys.includes(e.key)) {
        this.pressedKeys.push(e.key);
      }

      if (e.key === 'Escape' && this.isShowingCommandBar) {
        this.closeCommandBar();
      }

      const matches = this.pressedKeys.filter((element) =>
        ['Control', 'k'].includes(element),
      );

      if (matches.includes('Control') && matches.includes('k')) {
        e.preventDefault();
        if (!this.isShowingCommandBar) {
          this.showCommandBar();
        } else {
          this.closeCommandBar();
        }
      }
    },
    handleKeyup(e) {
      this.pressedKeys.splice(this.pressedKeys.indexOf(e.key), 1);
      if (e.key === 'ArrowDown') {
        this.selectedCommand++;

        if (this.selectedCommand > this.results.length - 1) {
          this.selectedCommand = 0;
        }
      }

      if (e.key === 'ArrowUp') {
        this.selectedCommand--;

        if (this.selectedCommand < 0) {
          this.selectedCommand = this.results.length - 1;
        }
      }

      if (e.key === 'Enter') {
        this.handleAction(this.results[this.selectedCommand].action);
      }
    },
    closeCommandBar() {
      this.isShowingCommandBar = false;
      this.search = '';
      this.selectedCommand = 0;
    },
    showCommandBar() {
      this.isShowingCommandBar = true;
      this.$nextTick(() => {
        if (this.$refs && this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
    showSubmenu(menu) {
      this.isShowingSubmenu = true;
      this.submenu = this.commands[menu].subcommands;
    },
    filterByTextContains(search) {
      const re = new RegExp(search, 'i');
      this.selectedCommand = 0;

      return this.activeMenu.filter((item) => {
        return (
          re.test(this.$i18n.t(item.label)) || re.test(item.keywords.join('|'))
        );
      });
    },
  },
};
