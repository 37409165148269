import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=1b1da72e&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AppFooter.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/opt/build/repo/components/footer/index.js').default})
