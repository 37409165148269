//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Navigation } from '@/components/nav';
import SkipToContent from '@/components/SkipToContent';
import AppFooter from '@/components/footer/AppFooter';
import CommandBar from '@/components/commandbar/CommandBar.vue';

export default {
  components: { Navigation, AppFooter, SkipToContent, CommandBar },
  data() {
    return {
      themes: ['system', 'light', 'dark'],
      commands: [
        {
          id: 'contact',
          label: 'contact',
          keywords: [
            'contact',
            'email',
            'e-mail',
            'whatsapp',
            'talk',
            "let's talk",
          ],
          action: () => this.$store.dispatch('showContactForm'),
        },
        {
          id: 'theme',
          label: 'theme',
          keywords: ['theme', 'dark', 'light', 'mode'],
          subcommands: [
            {
              icon: 'system',
              id: 'theme_system',
              label: 'theme_system',
              keywords: ['system'],
              action: () => (this.$colorMode.preference = 'system'),
            },
            {
              icon: 'dark',
              id: 'theme_dark',
              label: 'theme_dark',
              keywords: ['dark'],
              action: () => (this.$colorMode.preference = 'dark'),
            },
            {
              icon: 'light',
              id: 'theme_light',
              label: 'theme_light',
              keywords: ['light'],
              action: () => (this.$colorMode.preference = 'light'),
            },
          ],
          action: () => {
            this.$refs.commandbar.showSubCommands('theme');
          },
        },
        {
          id: 'change-language',
          label: 'change_language',
          keywords: [
            'change',
            'language',
            'i18n',
            'nederlands',
            'english',
            'engels',
            'dutch',
          ],
          action: () => {
            if (this.$i18n.locale === 'en') {
              this.$router.push({
                path: '/',
              });
            } else {
              this.$router.push({
                path: '/en',
              });
            }
          },
        },
      ],
    };
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttribute: true });
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
        ...i18nHead.htmlAttrs,
      },
      title: this.$i18n.t('title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('description'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$i18n.t('description'),
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: 'https://www.hemix.nl',
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$i18n.t('title'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://www.hemix.nl/og-hemix.png',
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:domain',
          name: 'twitter:domain',
          content: 'hemix.nl',
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: 'https://www.hemix.nl',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.$i18n.t('title'),
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.$i18n.t('description'),
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://www.hemix.nl/og-hemix.png',
        },
        ...i18nHead.meta,
      ],
      link: [...i18nHead.link],
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
  },
};
