//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconDark from '@/assets/svg/icons/moon-solid.svg';
import IconLight from '@/assets/svg/icons/sun-bright-solid.svg';
import IconSystem from '@/assets/svg/icons/desktop-solid.svg';

export default {
  name: 'CommandBarResults',
  components: {
    IconDark,
    IconLight,
    IconSystem,
  },
  props: {
    command: {
      required: true,
      type: Object,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
