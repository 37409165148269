//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommandBarResults from './CommandBarResults.vue';

export default {
  name: 'CommandBar',
  components: { CommandBarResults },

  props: {
    commands: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isShowingCommandBar: false, // Store
      pressedKeys: [],
      search: '',
      subCommands: [],
      isShowingSubCommands: false,
    };
  },
  computed: {
    activeCommands() {
      return this.isShowingSubCommands ? this.subCommands : this.commands;
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    showSubCommands(commandId) {
      const parent = this.commands.filter((c) => c.id === commandId)[0];
      if (parent && parent.subcommands.length > 0) {
        this.search = '';
        this.subCommands = parent.subcommands;
        this.isShowingSubCommands = true;
      }
    },
    matchesCombnination(array1, array2) {
      return array1.sort().every((val, index) => {
        return val === array2.sort()[index];
      });
    },
    hideCommandBar() {
      this.isShowingCommandBar = false;
      this.pressedKeys = [];
      this.search = '';
      this.isShowingSubCommands = false;
      this.subCommands = [];
    },
    hideSubCommands() {
      this.isShowingSubCommands = false;
      this.subCommands = [];
    },
    showCommandBar() {
      this.search = '';
      this.isShowingCommandBar = true;
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
    handleKeyDown(e) {
      if (!this.pressedKeys.includes(e.key)) this.pressedKeys.push(e.key);

      if (e.key === 'Escape' && this.isShowingCommandBar) {
        if (this.isShowingSubCommands) {
          this.hideSubCommands();
        } else {
          this.hideCommandBar();
        }
      }

      const combinations = {
        ctrlk: ['Control', 'k'],
      };

      if (this.matchesCombnination(combinations.ctrlk, this.pressedKeys)) {
        e.preventDefault();

        this.isShowingCommandBar
          ? this.hideCommandBar()
          : this.showCommandBar();
      }

      if (
        this.isShowingCommandBar &&
        ['ArrowDown', 'ArrowUp'].includes(e.key)
      ) {
        e.preventDefault();
      }
    },
    handleKeyUp(e) {
      this.pressedKeys.splice(this.pressedKeys.indexOf(e.key1), 1);
    },
  },
};
